import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

const Loader = (props) => (
  <CircularProgress color="secondary" size={props.size} variant="indeterminate" value={props.value} />
);

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  thickness: PropTypes.number,
  value: PropTypes.number,
};

export default Loader;
