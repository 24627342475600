import React, { useState } from "react";
import PropTypes from "prop-types";

export const SessionContext = React.createContext();

export const SessionProvider = ({ children }) => {
  const [context, setContext] = useState({ user: null });
  return <SessionContext.Provider value={[context, setContext]}>{children}</SessionContext.Provider>;
};

SessionProvider.propTypes = {
  children: PropTypes.element,
};
