import * as Sentry from "@sentry/browser";
import { render } from "react-dom";
import { BrowserTracing } from "@sentry/tracing";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import React, { StrictMode } from "react";
import { Amplify } from "aws-amplify";

import App from "./App.jsx";
import "./assets/tailwind/tailwind.css";
import theme from "assets/theme";
import awsconfig from "./aws-exports";
import MyContextProvider from "./store/MyContextProvider";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Sentry.Replay({ maskAllText: true, blockAllMedia: true })],
  tracesSampleRate: 1.0,
});

Amplify.configure(awsconfig);

const rootElement = document.getElementById("root");

render(
  <StrictMode>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MyContextProvider>
          <App />
        </MyContextProvider>
      </ThemeProvider>
    </StylesProvider>
  </StrictMode>,
  rootElement
);
