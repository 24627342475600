/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_UWbVO83ey",
  aws_user_pools_web_client_id: "7dg49qrcc63prgoanooljfocbv",
  oauth: {
    domain: "bikey-app-cyclists.auth.us-east-1.amazoncognito.com",
    scope: ["aws.cognito.signin.user.admin", "email", "openid", "phone", "profile"],
    redirectSignIn: "https://tracking.bikey.app/",
    redirectSignOut: "https://tracking.bikey.app/login/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: ["FACEBOOK", "GOOGLE", "APPLE"],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS"],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
  aws_appsync_graphqlEndpoint: "https://vuxypvqh4fgmxoc5ldmu3f46ym.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

export default awsmobile;
