import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Loader from "components/core/Loader";

const Fallback = ({ children }) => (
  <Suspense
    fallback={
      <div className="absolute left-0 right-0 top-0 bottom-0 flex justify-center items-center">
        <Loader />
      </div>
    }
  >
    {children}
  </Suspense>
);

Fallback.defaultProps = {
  children: <></>,
};

Fallback.propTypes = {
  children: PropTypes.node,
};

export default Fallback;
