import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from "@apollo/client/";
import { BrowserRouter } from "react-router-dom";
import React, { useContext, lazy, useEffect } from "react";
// Context
import { SessionContext } from "./store/Session.jsx";
// utils
import loggedIn from "./utils/loggedIn.js";
// Custom components
import Fallback from "./components/core/Fallback.jsx";
// Custom components - lazy loading
const MainNavigation = lazy(() => import("./components/navigation/MainNavigation.jsx"));

const App = () => {
  const [sessionContext, setSessionContext] = useContext(SessionContext);

  /* This is necessary  to re establish the session context 
  when the page is reloaded or re open. */
  useEffect(() => {
    async function fetchData() {
      const user = await loggedIn();
      user && setSessionContext({ ...sessionContext, user });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let client = new ApolloClient({
    uri: process.env.REACT_APP_URI_APOLLO,
    cache: new InMemoryCache({ addTypename: false }),
  });
  if (sessionContext.user) {
    const awsGraphqlFetch = async (uri, options) => {
      const user = await loggedIn();
      options.headers.Authorization = `${sessionContext.user?.pool?.userPoolId}@${await user?.signInUserSession
        ?.accessToken?.jwtToken}`;
      return fetch(uri, options);
    };

    const httpLink = new HttpLink({ uri: process.env.REACT_APP_URI_APOLLO, fetch: awsGraphqlFetch });
    client = new ApolloClient({ link: httpLink, cache: new InMemoryCache({ addTypename: false }) });
  }
  const content = (
    <ApolloProvider client={client}>
      <Fallback>
        <MainNavigation />
      </Fallback>
    </ApolloProvider>
  );

  return (
    <div>
      <Fallback>
        <BrowserRouter>{content}</BrowserRouter>
      </Fallback>
    </div>
  );
};

export default App;
