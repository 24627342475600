import { createTheme } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";

const theme = createTheme(
  {
    palette: {
      primary: {
        main: "#0C3131",
      },
      secondary: {
        main: "#4bb167",
      },
      neutral: {
        main: "#fff",
      },
      gray: {
        main: "#f1f1f1",
      },
      grayLetters: {
        main: "#888",
      },
      red: {
        main: "#E53935",
      },
      green: {
        main: "#5AC18E",
      },
      transparent: {
        main: "transparent",
      },
      icon1: {
        main: "42779b",
      },
      icon2: {
        main: "icon2",
      },
      icon3: {
        main: "2c5d7e",
      },
      icon4: {
        main: "639dc5",
      },
      icon5: {
        main: "215070",
      },
      disabled: {
        main: "#E0E0E0",
      },
    },
    typography: {
      fontFamily: ["Raleway"].join(","),
    },
  },
  esES
);

export default theme;
