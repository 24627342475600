import { Auth } from "aws-amplify";

const loggedIn = async () => {
  let user = null;
  try {
    user = await Auth.currentAuthenticatedUser();
    return user;
  } catch {
    return user;
  }
};

export default loggedIn;
