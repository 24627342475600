import React from "react";
import PropTypes from "prop-types";
import CombineComponents from "components/core/CombineComponents";
import { SessionProvider } from "./Session";

const contexts = [SessionProvider];

const MyContextProvider = ({ children }) => <CombineComponents components={contexts}>{children}</CombineComponents>;

MyContextProvider.propTypes = {
  children: PropTypes.element,
};

export default MyContextProvider;
