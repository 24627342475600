import React from "react";
import PropTypes from "prop-types";

function CombineComponents(props) {
  const { components = [], children } = props;

  return (
    <>
      {components.reduceRight((AccumulatedComponents, CurrentComponent) => {
        return <CurrentComponent>{AccumulatedComponents}</CurrentComponent>;
      }, children)}
    </>
  );
}

CombineComponents.propTypes = {
  components: PropTypes.array,
  children: PropTypes.element,
};

export default CombineComponents;
